import {Flex} from "@chakra-ui/react";
import MemeGallery from "../components/MemeGallery";
import MemeUpload from "../components/MemeUpload";

export default function Memes() {
	return (
		<Flex direction="column" bg="gray.600" p="10" h="auto" flex="1">
			<MemeUpload />
			<MemeGallery />
		</Flex>
	);
}
