import {InputGroup} from "@chakra-ui/react";
import React, {useRef} from "react";

export default function PhototUploader(props) {
	const {register, children} = props;
	const inputRef = useRef;
	const {ref} = register;
	const handleClick = () => inputRef.current?.click();

	return (
		<InputGroup onClick={handleClick}>
			<input
				type={"file"}
				multiple={false}
				accept={"image/*"}
				hidden
				ref={(e) => {
					ref(e);
					inputRef.current = e;
				}}
				onChange={props.onChange}
			/>
			<>{children}</>
		</InputGroup>
	);
}
