import {Box, Container, Text, VStack} from "@chakra-ui/react";
import React from "react";
import {Link} from "react-router-dom";
export default function NavBar() {
	return (
		<Box bg="gray.600" color="black" flexShrink="1" flexBasis="auto">
			<Container
				as={VStack}
				py={4}
				direction="column"
				spacing={4}
				justify="center"
				align="center"
			>
				<Link to="/">
					<Box bg="gray.400" p="5">
						<Text fontSize="4xl">Meme Gallery</Text>
					</Box>
				</Link>

				<Link to="/stats">
					<Text fontSize="lg" color="gray.300" as="u">
						See stats
					</Text>
				</Link>
			</Container>
		</Box>
	);
}
