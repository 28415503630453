import {configureStore} from "@reduxjs/toolkit";
import memeReducer from "./memeSlice";
import statsReducer from "./statsSlice";

export default configureStore({
	reducer: {
		meme: memeReducer,
		stats: statsReducer,
	},
});
