import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import memer from "../api/memer";

export const getMemes = createAsyncThunk("meme/getMemes", async () => {
	const response = await memer.get(`/meme/all`);
	return response.data;
});

export const uploadMeme = createAsyncThunk(
	"meme/uploadMeme",
	async (params) => {
		const response = await memer.post(`/meme/`, params, {
			headers: {"content-type": "multipart/form-data"},
		});
		return response.data;
	}
);

export const uploadMemeByLink = createAsyncThunk(
	"meme/uploadMemeByLink",
	async (params) => {
		const response = await memer.post(`/meme/uploadByLink`, params);
		return response.data;
	}
);

export const deleteMeme = createAsyncThunk(
	"meme/deleteMeme",
	async (memeID) => {
		await memer.delete(`/meme/${memeID}`);
		return memeID;
	}
);

const initialState = () => {
	return {
		all: [],
	};
};

const memeSlice = createSlice({
	name: "meme",
	initialState: initialState(),

	extraReducers: {
		[getMemes.fulfilled]: (state, action) => {
			state.all = action.payload;
		},
		[uploadMeme.fulfilled]: (state, action) => {
			state.all.push(action.payload.data);
		},
		[uploadMemeByLink.fulfilled]: (state, action) => {
			state.all.push(action.payload.data);
		},

		[deleteMeme.fulfilled]: (state, action) => {
			state.all = state.all.filter((meme) => meme._id !== action.payload);
		},
	},
});

export default memeSlice.reducer;
