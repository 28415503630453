import {Box, Flex, Text} from "@chakra-ui/react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import StatsChart from "../components/StatsChart";
import {getStats} from "../store/statsSlice";

export default function Stats() {
	const dispatch = useDispatch();
	const stats = useSelector((state) => state.stats.lastWeek);

	useEffect(() => {
		dispatch(getStats());
	}, [dispatch]);
	return (
		<Flex
			bg="gray.600"
			direction="column"
			alignItems="center"
			h="auto"
			flex="1"
			pt="10"
		>
			<Box fontSize="lg" bg="gray.300" px="10" textAlign="center">
				<Text fontSize="4xl">Stats</Text>
			</Box>
			<Box w="80vw">
				<StatsChart
					statsData={stats}
					barDataKey="totalUploads"
					title="Memes uploaded per day last 7 days"
				/>
				<StatsChart
					statsData={stats}
					barDataKey="totalDeletes"
					title="Memes deleted per day last 7 days"
				/>
			</Box>
		</Flex>
	);
}
