import {Box, Text} from "@chakra-ui/react";
import {
	Bar,
	BarChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

export default function StatsChart({statsData, barDataKey, title}) {
	return (
		<>
			<Text color="gray.300" textAlign="center" py="8">
				{title}
			</Text>
			<Box borderWidth="1px" p="10">
				<ResponsiveContainer width="100%" height={400}>
					<BarChart data={statsData}>
						<XAxis
							stroke="white"
							tick={{fill: "white"}}
							dataKey="displayDate"
						/>
						<YAxis stroke="white" tick={{fill: "white"}} />
						<Tooltip cursor={{fill: "transparent"}} />
						<Bar dataKey={barDataKey} fill="#CBD5E0" />
					</BarChart>
				</ResponsiveContainer>
			</Box>
		</>
	);
}
