import {
	Button,
	Flex,
	FormControl,
	Icon,
	Input,
	InputGroup,
	InputRightAddon,
} from "@chakra-ui/react";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {FiFile} from "react-icons/fi";
import {useDispatch} from "react-redux";
import {uploadMeme, uploadMemeByLink} from "../store/memeSlice";
import PhototUploader from "./PhototUploader";

const MemeUpload = () => {
	const [link, setLink] = useState();
	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: {errors},
	} = useForm();

	const onSubmit = handleSubmit((data) => {
		let formData = new FormData();
		formData.append("memePhoto", data.file[0]);
		dispatch(uploadMeme(formData));
	});

	return (
		<Flex
			justify="space-between"
			pb="10"
			wrap="wrap"
			direction={{base: "column", lg: "row"}}
		>
			<InputGroup
				grow="1"
				w={{base: "auto", lg: "2xl"}}
				pb={{base: "6", lg: "0"}}
			>
				<Input
					color="white"
					placeholder="Meme image link"
					onChange={(e) => setLink(e.target.value)}
				/>
				<InputRightAddon
					children={
						<Button variant="ghost" _focus={{boxShadow: "none"}}>
							Add Meme
						</Button>
					}
					type="text"
					onClick={() => dispatch(uploadMemeByLink({link: link}))}
				/>
			</InputGroup>

			<form onSubmit={onSubmit}>
				<FormControl isInvalid={!!errors.file_} isRequired>
					<PhototUploader
						register={register("file")}
						onChange={onSubmit}
					>
						<Button
							as="button"
							w={{base: "100%", lg: "auto"}}
							leftIcon={<Icon as={FiFile} />}
						>
							Upload Meme
						</Button>
					</PhototUploader>
				</FormControl>
			</form>
		</Flex>
	);
};

export default MemeUpload;
