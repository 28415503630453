import {DeleteIcon} from "@chakra-ui/icons";
import {Box, Flex, IconButton, Image} from "@chakra-ui/react";
import {useDispatch} from "react-redux";
import {deleteMeme} from "../store/memeSlice";

function MemeCard({meme}) {
	const dispatch = useDispatch();
	return (
		<Box position="relative" borderWidth="1px" h={{lg: "500px"}}>
			<Image
				src={`${process.env.REACT_APP_BACKEND_IMAGE_HOST}/${meme.memeURL}`}
				w="100%"
				h="100%"
			/>
			<Flex p="2" top={0} right={0} position="absolute">
				<IconButton
					colorScheme="gray"
					size="md"
					icon={<DeleteIcon />}
					onClick={() => dispatch(deleteMeme(meme._id))}
				></IconButton>
			</Flex>
		</Box>
	);
}

export default MemeCard;
