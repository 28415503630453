import {Grid} from "@chakra-ui/react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getMemes} from "../store/memeSlice";
import MemeCard from "./MemeCard";

export default function MemeGallery() {
	const dispatch = useDispatch();
	const memes = useSelector((state) => state.meme.all);

	useEffect(() => {
		dispatch(getMemes());
	}, [dispatch]);

	return (
		<Grid
			templateColumns={{base: "repeat( 1, 1fr)", lg: "repeat( 3, 1fr)"}}
			gap={6}
		>
			{memes.map((meme) => {
				return <MemeCard key={meme._id} meme={meme} />;
			})}
		</Grid>
	);
}
