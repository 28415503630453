import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import memer from "../api/memer";

export const getStats = createAsyncThunk("meme/getStats", async () => {
	const response = await memer.get(`/stats/lastWeek`);
	return response.data;
});

const initialState = () => {
	return {
		lastWeek: [],
	};
};

const statsSlice = createSlice({
	name: "stats",
	initialState: initialState(),

	extraReducers: {
		[getStats.fulfilled]: (state, action) => {
			state.lastWeek = action.payload;
		},
	},
});

export default statsSlice.reducer;
