import {Box, Flex} from "@chakra-ui/react";
import React from "react";
import {BrowserRouter} from "react-router-dom";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import {AppRoutes} from "./routes";

function App() {
	return (
		<div className="App">
			<Box background="primaryBgColor">
				<Flex direction="column" height="100vh">
					<BrowserRouter>
						<NavBar />
						<AppRoutes />
						<Footer />
					</BrowserRouter>
				</Flex>
			</Box>
		</div>
	);
}

export default App;
