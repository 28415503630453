import {Box, Container, Stack, Text} from "@chakra-ui/react";

export default function Footer() {
	return (
		<Box
			bg="gray.600"
			color="black"
			display="flex"
			flexShrink="1"
			flexBasis="80px"
		>
			<Container
				as={Stack}
				py={4}
				spacing={4}
				justify="center"
				align="center"
			>
				<Text color="gray.300">© Refat Chowdhury</Text>
			</Container>
		</Box>
	);
}
