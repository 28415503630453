import {Route} from "react-router-dom";
import Memes from "../pages/Memes";
import Stats from "../pages/Stats";

export const AppRoutes = () => {
	return (
		<>
			<Route path="/" exact component={Memes}></Route>
			<Route path="/stats" exact component={Stats}></Route>
		</>
	);
};
